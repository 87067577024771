import React from "react"
import Layout from "../components/Layout"
import big from "../assets/images/bgmain.jpg"
import styled from "styled-components"
import "bootstrap/dist/css/bootstrap.min.css"
import { Container, Row, Col } from "react-bootstrap"
import { StaticImage } from "gatsby-plugin-image"
import { Link } from "gatsby"

//para espacio de spacer hacer pequeno el background
const spacer = 20

//para agrandamiento de segundo porcentaje de background
const percentage = 200

const Franja = styled.div`
  background: black;
  height: 60px;
`

const TituloBox = styled.div`
  font-size: 38px;
  font-weight: 700;
  font-family: "Titillium Web";
  letter-spacing: -2px;
  color: white;
`
const MensajeBox = styled.div`
  font-size: 58px;
  font-weight: 700;
  font-family: "Titillium Web";
  letter-spacing: -2px;
  text-align: center;
  padding-top: 50px;
  padding-bottom: 50px;
  @media (max-width: 768px) {
    font-size: 30px;
  }
`

const LeftBox = styled.div`
  font-size: 25px;
  font-weight: 500;
  font-family: "Titillium Web";
  letter-spacing: -2px;
  /* text-align: center; */
  padding-top: 50px;
  padding-bottom: 50px;
`

const RightBox = styled.div`
  font-size: 25px;
  font-weight: 500;
  font-family: "Titillium Web";
  /* letter-spacing: -2px; */
  /* text-align: center; */
  padding-top: 50px;
  padding-bottom: 50px;
`
const Boldie = styled.div`
  font-weight: bold;
`

const Reinge = () => {
  return (
    <Layout sbiggo={big} spacer={spacer} percentage={percentage}>
      <Franja>
        <Container>
          <Row>
            <Col>
              <TituloBox>Re Ingenieria Web</TituloBox>
            </Col>
          </Row>
        </Container>
      </Franja>
      <Container>
        <Row>
          <Col>
            <MensajeBox>
              Brindamos servicios de re estructuración, mejora, migración y
              puesta en marcha de sitios web
            </MensajeBox>
          </Col>
        </Row>
      </Container>
      <StaticImage
        src="../assets/images/reweb.jpg"
        alt="web"
        aspectRatio={26 / 9}
      />
      <Container>
        <Row>
          <Col md={3} className="d-none d-lg-block">
            <LeftBox>
              <Boldie>Contáctenos: </Boldie>
              <br /> info@yaku.com.ec <br />
              +593999944405
            </LeftBox>
          </Col>
          <Col md={9} xs={12} className="d-lg-block">
            <RightBox>
              En Yaku recuperamos su inversión en tecnología web. Realizamos
              trabajos técnicos para reparar, migrar o mejorar su sitio web.
              <br /> <br />
              Si usted tiene alguno de los siguientes problemas en su sitio web
              puede causar que su sitio al corto o medio plazo deje de
              funcionar: <br /> <br /> <br />
              1. No actualizado los componentes de su sitio en un espacio
              temporal superior a 3 meses <br /> <br />
              2. La velocidad de su sitio web cada vez es menor. Las páginas
              cargan lento, se presentan errores de carga de imágenes o páginas
              <br /> <br />
              3. Aparecen constamente mensajes desconocidos en su correo
              electrónico que vienen desde su sitio web <br /> <br />
              4. No cuenta con soporte técnico para su sitio web o hosting
              <br /> <br />
              5. Al realizar una revisión de errores internos de su hosting
              encuentra gran cantidad de errores desconcidos, exceso de uso de
              ancho de banda <br /> <br />
              Estas señales son claves para tomar la decisión de realizar un
              proceso de re-ingenieria web a su sitio web. <br />
              <br /> <br />
              Es posible que su sitio web requiera realizar los siguientes
              cambios: <br /> <br />
              1. Optimización de componentes internos de su sitio web o su
              reemplazo <br /> <br />
              2. Optimización de imágenes <br /> <br />
              3. Ajustes en el hosting de su sitio web <br /> <br />
              4. Cambio de tecnología de su sitio web <br /> <br />
              5. Migración de su sitio web a un nuevo hosting <br /> <br />
              6. Pruebas de performance en dispositivos móviles <br /> <br />
              7. Creación de sitio de pruebas para probar cambios estructurales
              <br /> <br />
              8. Otros <br /> <br />
              <br />
              En Yaku hemos dado asesoría y solución a sitios web con problemas
              estructurales, tenemos la experiencia y el equipo técnico para
              poder dar vida a su proyecto tecnológico con problemas.
              <br /> <br /> <br />
              <Boldie>Experiencia</Boldie>
              <br /> <br />
              Hemos creado soluciones para empresas líderes del sector privado.
              También trabajamos con proyectos del sector público. Con mucho
              gusto le podemos hacer llegar nuestro portafolio de proyectos
              creados. Contáctenos pronto!
              <br /> <br />
              Mantenemos una amplia cartera de clientes que han confiado en
              nuestro trabajo. Hemos creado sitios web, aplicaciones web,
              aplicaciones sociales, entre otros. <br />
              <br />A través de los años hemos incrementado el uso de nuevas
              tecnologías que le permitan a nuestros clientes poder solventar
              cualquier requerimiento sin importar el grado de complejidad.
              <br />
              <br />
              ¿Tiene un nuevo proyecto? Permítanos ayudarle a conseguir los
              objetivos planificados. Nuestro soporte es personalizado, somos
              parte de su equipo y nos comprometemos a obtener las metas de cada
              proyecto. <br />
              <br />
              <br />
            </RightBox>
          </Col>
        </Row>
      </Container>
    </Layout>
  )
}

export default Reinge
